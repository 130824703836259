<template>
  <v-sheet class="container">
    <div class="mx-2 my-5 text-center text-subtitle-1 font-weight-light">
      <div class="d-flex justify-center text-caption">
        QR Code {{this.qrCodeId}} <v-divider class="mx-2" vertical /> ({{$vuetify.lang.t('$vuetify.worksheet.xWorksheets', pagination.totalSize)}})
      </div>
    </div>
    <v-divider />
    <infinite-scroll @fetchData="fetchData" :totalPages.sync="pagination.totalPages">
      <worksheet-card v-for="(worksheet, index) in worksheets" :worksheet="worksheet" :index="index+1" :key="worksheet ? worksheet.id:'null'"/>
    </infinite-scroll>
  </v-sheet>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
const WorksheetCard = () =>
  import("@/components/processWorksheet/WorksheetCard.vue");

export default {
  props:{
    qrCodeId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      pagination: {
        pageIndex: 0,
        pageSize: 5,
        totalSize: 100,
      },
      worksheets: [],
      options: {
        sortBy: ["createTime"],
        sortDesc: ["true"],
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    ...mapGetters("worksheetTypeDefinition", ["worksheetTypeDefinitions"]),
    ...mapGetters("navigation", ["isAppLoading"]),
  },
  components: {WorksheetCard},
  mounted() {
    this.fetchWorksheetTypeDefinitions();
  },
  methods: {
    ...mapActions("worksheetTypeDefinition", ["fetchWorksheetTypeDefinitions"]),

    prepParams(page, pageSize) {
      let sortBy =
        this.options["sortBy"] && this.options["sortBy"].length > 0
          ? this.options.sortBy[0]
          : "createTime";
      if (sortBy == "productName") {
        sortBy = "product.name";
      }
      if (sortBy == "processTypeName") {
        sortBy = "processType.name";
      }

      const sortDesc =
        this.options["sortDesc"] && this.options["sortDesc"].length > 0
          ? this.options.sortDesc[0]
          : true;
      const params = {
        page,
        size: pageSize,
        sort: `${sortBy}${sortDesc ? ",desc" : ""}`,
        qrCodeId: this.qrCodeId
      };

      return params;
    },
    
    fetchData({page, done, empty}){
        
        const params = this.prepParams(page, this.pagination.pageSize);
        this.$axios("/processWorksheet/by-qr-code", { params })
          .then((resp) => {
            const newPage = resp.data.content;
            this.worksheets = this.worksheets.concat(newPage);
            this.pagination.totalSize = resp.data.totalElements;
            this.pagination.totalPages = resp.data.totalPages;
            if(this.pagination.totalSize > 0){
              done();
            }
            else{ 
              empty();
            }
          })
          .catch((error) => {
            console.log(error.data.message);
            empty();
          });
    }
  },
};
</script>

<style></style>
